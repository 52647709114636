// import "./App.css";
import React, { useEffect, useState } from "react";
import { 
    Map,
    Marker,
    useMapsLibrary,
    useMap

} from "@vis.gl/react-google-maps";



const CustomMap = () => {
  // shows marker on London by default
  const [markerLocation, setMarkerLocation] = useState({
    lat: 33.78798,
    lng: -117.89790,
  });

  return (
    <div className="map-container">
      <Map
        style={{ borderRadius: "20px" }}
        defaultZoom={19}
        defaultCenter={markerLocation}
        gestureHandling={"greedy"}
        center={{ lat: 33.78798, lng: -117.89791 }}
        disableDefaultUI
      >
        <Marker position={markerLocation} />
       
      </Map>
    </div>
  );
}

function Directions(){
    const map = useMap();
    const routesLibrary =  useMapsLibrary("routes");
    // const [directionsService, setDirectionsService] = useStategoogle.maps.DirectionsService>(();
    // const [directionsRenderer, setDirectionsRenderer] = useStategoogle.maps.DirectionsService>();
    // const [directionsService, setDirectionsService] = useState<google.maps.DirectionsService>(any);

    useEffect(()=> {
        if (!routesLibrary || map ) return;
        // here you have access to the routesLibrary and the map
        // setDirectionsService(new routesLibrary.DirectionsService());
        // setDirectionsRenderer(new routesLibrary.DirectionsRenderer({maps}));
    }, [routesLibrary, map]);

    // console.log(directionsService)
     return null;
}

export default CustomMap;
