
import Swiperslider from "./components/Swiperslider";
import slides from './mock.json';
import SearchBar from "./components/SearchBar";
import "./styles.css"
import rcboLogo from './logo-1-1.png';
import DonorData from './donors_data.json';
import React, { useState } from "react";
import DonorDetail from "./components/DonorDetail";
import Location from "./components/Location";


function App() {

  const handleDatafromSearch = (specdonordata)=>{
    // console.log('Do a search with ',specdonordata);
    setDonor(specdonordata);
  }
  const [donor, setDonor] = useState([])
  console.log('My Donor',donor)

  const defaultImageName = 'lavang.jpg';
const imageName = donor.pane_image_name === undefined || donor.pane_image_name === null  ? defaultImageName : donor.pane_image_name
console.log('My imageName',imageName)


const toDate = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  const currentDate = new Date();
  const date = `${days[currentDate.getDay()]} ${currentDate.getDate()} ${months[currentDate.getMonth()]
    }`;
  return date;
};


  return (
    <div className="App">

    <div className="column-pair">

        <div className="column">
       
    <img src={rcboLogo} className="logo" alt="RCBO Logo"/>
        </div>

        <div class="column">
       
        {
           <SearchBar placeholder="Enter Donor Name..." data={DonorData} passedData={handleDatafromSearch} />
          
         }
        </div>

    </div>
    {
      !donor.location 
      ? 
     <Swiperslider slides={slides} />
     :
<div>{/*beginning div */}
     <div className="single-row">
        <div className="row-item">
            <DonorDetail donordatadetails={donor}  />
        </div>
     </div>

     <div className="column-pair">

     <div className="column">
     
     <div 
     style={{ 
   
       backgroundImage: `url(${process.env.PUBLIC_URL}/images/${imageName})`,
       backgroundRepeat: "no-repeat",
       backgroundSize: "cover",
       backgroundPosition: "center",
       height:"77.5vh"       

     }}
    

     >

     </div>
     </div>

     <div className="column">
     <div className="pane-info">
     {
        <Location toDate={toDate} donordatadetails={donor} />

    }
     </div>
     
   
 
     </div>

 </div>
  {/*ending div */}
</div>
   
   
     

     

    }
       
   

    </div>
  );
}

export default App;
