import React  from "react";
import CustomMap from "./CustomMap";
// import DirectionMap from "./DirectionMap";
import  imageFirst  from '../img/MarianGardens_map.png';
import { APIProvider } from "@vis.gl/react-google-maps";


function Location({ donordatadetails }) {

// console.log(donordatadetails);


  return (
    <div className="location_details">
    
    {
      donordatadetails.location ? 
 
    <div className="custom-map">
       <APIProvider apiKey="AIzaSyBAepPr9WV9SeBCgoACBFQRagy8jWjQ2eo">
      <CustomMap />
    </APIProvider>     
      <p className="here_info"> {donordatadetails.fullname} you are here!</p>
      </div>
         : ''
    }
      
     
         {
          donordatadetails.location ?  <h4>YOUR NAME IS LOCATED AT  {donordatadetails.location} OF THE DEDICATION WALL</h4> : ''
        }

        {
          donordatadetails.location ? <p>  Please follow the directions below to get there</p> : ''
        }


        {
          donordatadetails.location ? <div><img src={imageFirst} alt="OLLV Shrine" /> </div> : ''
        }


    </div>
  );
}        

export default Location;