import React from 'react';

const DonorDetail = ({ donordatadetails }) => {
// console.log(donordatadetails.pane_image_name);
  return (
    <div>
    {
      donordatadetails.fullname  ?  <h2>Welcome <br/> {donordatadetails.fullname}!</h2> : <h2>Welcome to<br/> RCBO Campus!</h2>
   }
    </div>
  );
};

export default DonorDetail;