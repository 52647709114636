// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCube } from 'swiper/modules';
// Import Swiper styles
// import 'swiper/css';
// import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-cube';
// import 'swiper/css/scrollbar';
import React from 'react';

// import "../styles.css"

function Swiperslider({slides}){
    return(
       
        <Swiper
        modules={[EffectCube,Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        
        // navigation
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        effect={'cube'}
        cubeEffect={{
          // shadow: true,
          // slideShadows: true,
        
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
      >
      {slides.map((slide) => (
        <div className='swiper-slide'>
        <SwiperSlide key={slide.id}>
          <img src={slide.image} alt={slide.title} />
            <div className='slide-content'><h1>{slide.title}</h1></div>
         
          
        </SwiperSlide>
        </div>
    ))}
    
    
    
      </Swiper>
      
   
)
    
}


export default Swiperslider;