import React, {useState} from 'react'
import './SearchBar.css';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// import DonorDetail from './DonorDetail';
// import Forecast from './Forecast';

// props placeholder and data from App.js
function SearchBar({placeholder,data,passedData}) {
  // styles = { height: '200'};
  const [styles, setStyles] = useState({});

  const handleDonorClick = (donorme) => {
    passedData(donorme);
    // console.log(donorme);
    // set style of DataResult
    // setStyles({ height: '0' });
// clear data
    setFilteredData([]);
    setWordEntered('');

    // Reload or revert to the default page
     // Set a timeout to refresh the page after 45 seconds
     setTimeout(() => {
      window.location.reload();
    }, 45000); 
  
  };

// useState to hold the keyword we are searching and the filtered data list

const [filteredData, setFilteredData] = useState([]); // to hold an array
const [wordEntered, setWordEntered] = useState(''); // to hold an string
const [selectedDonor, setSelectedDonor] = useState(null); // to hold an string

const [showChild, setShowChild] = React.useState(false);

  const handleFilter = (event) => {
  // event is an argument for the event of the input
  // then access the event
  const searchedWord = event.target.value;
  // console.log(searchedWord)
  setWordEntered(searchedWord);
  // logic to filter data to only show the names similar to what we are searching using a new array newFilter
  const newFilter =  data.filter((value)=>{
    // return the array that includes or similar to the search keyword
    // return value.title.includes(searchedWord);
    // set toLowerCase() to match the keyword with the lowercase value of the title
    return value.fullname.toLowerCase().includes(searchedWord.toLowerCase());
  })

  // before we setFiltered is to ask if searchedWord is empty to set filteredData to an empty array
  if(searchedWord === ""){
    setFilteredData([]);
  }else{
    setFilteredData(newFilter);
    
  }
  

}

const clearInput = () => {
  setFilteredData([]);
  setWordEntered('');
}

// const handleDonorClick = (donor)=>{
//   setShowChild(true);
//   setSelectedDonor(donor);
  
// };



// console.log(selectedDonor);


  return (
    <div className='search'>
    <div className="searchInputs">
    <input type="text" placeholder={placeholder} value={wordEntered} onChange={handleFilter} />
    <div className="searchIcon">
    {
      // filteredData.length === 0 &&
       wordEntered === '' ? <SearchIcon /> : <CloseIcon id="clearButton" onClick={clearInput} />
    }    
    </div>
    </div>
    { filteredData.length !=0 && (
    // <div className="dataResult">
    <div className="dataResult">
        {
          // slice is added to display only 15 best matches
        filteredData.slice(0,25).map((row, index)=>{
          return <div key={index}>
          <a className="dataItem" onClick={()=>handleDonorClick(row)} ><p>{row.fullname}</p></a>
         
        
          </div>
            
        })}

        

    </div>
  )}
{
//  <DonorDetail selected_donor={selectedDonor}/>
}

 
    </div>
  )
}

export default SearchBar